import * as styles from './navbar.module.scss';

import React, { useRef, useState } from 'react';

import CloseMenu from '-!svg-react-loader!../../assets/close-menu.svg';
import DividerLine from '../DividerLine';
import { Link } from 'gatsby';
import MenuIcon from '-!svg-react-loader!../../assets/menu.svg';
import { StaticImage } from 'gatsby-plugin-image';

const pages = [
  {
    url: '/dance',
    name: 'Danza',
  },
  {
    url: '/fitness',
    name: 'Fitness',
  },
  {
    url: '/timetable',
    name: 'Orari',
  },
  {
    url: '/teachers',
    name: 'Insegnanti',
  },
  {
    url: '/gallery',
    name: 'Gallery',
  },
  {
    url: '/contacts',
    name: 'Contatti',
  },
  {
    url: '/regulations',
    name: 'Regolamento',
  },
];

const Navbar = ({ isHomePage = false }) => {
  const [showSideNav, setShowSideNav] = useState(false);
  const ulRef = useRef();

  const openMobileMenu = () => setShowSideNav(true);

  const closeMobileMenu = () => setShowSideNav(false);

  const fadeStyle = isHomePage && !showSideNav ? styles.navBackgroundFade : null;

  return (
    <div
      className={`${styles.navWrapper} ${fadeStyle}`}
      style={{ backgroundColor: showSideNav ? 'var(--light-gray)' : 'transparent' }}
    >
      <div className={styles.navContainer}>
        <div className={styles.logo}>
          <Link to="/">
            <StaticImage src="../../images/pilart-logo.png" alt="Logo" />
          </Link>
        </div>
        <nav>
          <ul ref={ulRef} className={showSideNav ? styles.show : null}>
            {pages.map(({ url, name }) => (
              <li key={name}>
                <Link to={url} partiallyActive activeClassName={styles.active} className={styles.pageLink}>
                  {name}
                </Link>
              </li>
            ))}
          </ul>
          {!showSideNav ? (
            <button onClick={openMobileMenu} aria-label="Apri menu">
              <MenuIcon className={`${styles.menuIcon} ${isHomePage && styles.homePageMenuIcon}`} />
            </button>
          ) : (
            <button onClick={closeMobileMenu} aria-label="Chiudi menu">
              <CloseMenu className={styles.menuIcon} />
            </button>
          )}
        </nav>
      </div>
      <DividerLine />
    </div>
  );
};

export default Navbar;
